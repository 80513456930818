import React from "react";


export default function PageNotFound() {

    return (
        <div>
            <h1>404 Error</h1>
            <h2>Page Not Found</h2>
        </div>
    );
}