import axios from "axios";

const PUMPHAUS_URL = "https://pumphaus.us-east-1.elasticbeanstalk.com";

var qs = require('qs');

export default async function getAPIResponse(route = "", paginate = false, parameters = {}) {
    let user = localStorage.getItem("authToken");
    const pageLimit = 100; 
    try {
        if (user) {
            let headers = { headers: { "Authorization": `Token ${user}` } };
            let params = {
                headers: {
                    "Authorization": `Token ${user}`
                },
                params: parameters, paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                }
            };
            let data = [];
            var url = `${PUMPHAUS_URL}/${route}`;
            let first = true;
            let nextPage = true;
            let res;

            // loop through pages
            if (paginate) {
                let counter = 0;
                while (nextPage && counter <= pageLimit && (res = await axios.get(url, first ? params : headers))) {
                    data.push(res);
                    let next = res['data']['next'];
                    if (next) {
                        first = false;
                        url = next;
                    } else {
                        nextPage = false;
                    }
                    counter += 1;
                }
            } else {
                let res = await axios.get(url, params);
                data.push(res);
            }
            return data;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function post(route, data) {
    let user = localStorage.getItem("authToken");
    try {
        if (user) {
            let headers = { headers: { "Authorization": `Token ${user}` } };
            let res = await axios.post(`${PUMPHAUS_URL}/${route}`, data, headers);
            return res;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function patch(route, data) {
    let user = localStorage.getItem("authToken");
    try {
        if (user) {
            let headers = { headers: { "Authorization": `Token ${user}` } };
            let res = await axios.patch(`${PUMPHAUS_URL}/${route}`, data, headers);
            return res;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}