import getAPIResponse from "./PumphausModel";
import { post, patch } from "./PumphausModel";

export const SiteKind = {
    USAPump: 1,
    AfricaPump: 2,
    AfricaFilter: 3,
}

export const NoteKind = {
    Default: 1,
    Alarm: 2,
}


export default async function getSites(siteId, parameters = {}) {
    const getSiteList = (ws, siteList) => {
        let ws_status = ws['status'];
        let status = null;
        if (ws_status) {
            status = new SiteStatus(
                ws_status['operating_status_latest_time'],
                ws_status['operating_status_start_time'],
                ws_status['operating_status'],
                ws_status['gateway_last_sync_at'],
                ws_status['last_pulse_at'],
                ws_status['last_calibration_at'],
                );

        }
        let installs = ws['installations'];
        let installations = [];
        if (installs) {
            for (const install of installs) {
                let i = new Installation(
                    install['id'],
                    install['installed_time'],
                    install['removed_time'],
                    install['has_controls'],
                    install['notes'],
                    install['created_at'],
                    install['updated_at'],
                    install['gateway'],
                    install['site'],
                    install['is_ongoing']);
                installations.push(i);
            }
            installations.sort((a, b) => (a.installedTime < b.installedTime) ? 1 : -1);
        }
        let location = new SiteLocation(ws['latitude'], ws['longitude']);
        let newSite = new Site(
            ws['id'],
            ws['meter'],
            ws['site_name'],
            location,
            ws['timezone'],
            status,
            ws['updated_at'],
            ws['site_test'],
            installations,
            ws['kind'],
            );

        siteList.push(newSite);
    }

    try {
        let route = "sites";
        if (siteId) {
            route += `/${siteId}`;
        }
        parameters['per_page'] = 1000;
        let results = await getAPIResponse(route, (siteId != null), parameters);
        let siteList = [];

        // one site vs multiple
        if (siteId) {
            getSiteList(results[0].data, siteList);
        } else {
            for (const res of results) {
                res.data.results.map((ws) => getSiteList(ws, siteList));
            }
        }
        return siteList;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getGatewayInfo(gatewayIds) {
    try {
        let gateways = [];
        for (const id of gatewayIds) {
            let route = `gateways/${id}`;
            let results = await getAPIResponse(route);
            gateways.push(results[0].data);
        }

        return gateways;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getPhotos(installationId) {
    try {
        let route = `installations/${installationId}`;
        let results = await getAPIResponse(route);
        let photos = [];
        for (const res of results[0].data.photos) {
            photos.push(new Photo(
                res['thumbnail_presigned_url'],
                res['presigned_url']));
        }
        if (photos.length > 0) {
            return photos;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getUserSiteKinds() {
    try {
        let route = `site_kind_counts/`;
        let response = await getAPIResponse(route);
        let results = response[0].data.results;
        let kinds = [];
        for (let result of results) {
            let resultKind = result['kind'];
            kinds.push(resultKind);
        }
        return kinds;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getAlarms(siteIds) {
    try {

        let route = `alarms/`;
        let response = await getAPIResponse(route, true, { site: siteIds, per_page:1000, unacknowledged:true });
        let results = response[0].data.results;
        let alarms = [];
        for (let result of results) {
            alarms.push(new Alarm(
                result['id'],
                result['site'],
                result['status'],
                result['created_at'],
                result['updated_at'],
                result['acknowledged_at'],
                result['acknowledged_by'],
            ));
        }
        alarms.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1);
        return alarms;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function acknowledgeAlarm(alarmId) {
    try {
        let route = `alarms/${alarmId}/`;
        let parameters = {
            "acknowledged_at": new Date().toISOString()
        };
        let response = await patch(route, parameters);
        console.log(response);
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export async function getPrecip(siteIds) {
    try {
        let route = `precipitation/`;
        let response = await getAPIResponse(route, true, { site: siteIds });
        let results = response[0].data.results;
        let precip = [];
        for (let result of results) {
            precip.push(new Precipitation(
                result['id'],
                result['created_at'],
                result['time'],
                result['value'],
                result['site'],
                result['aggregation'],
                result['source'],
            ));
        }
        return precip;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getStatuses(siteIds, distinct=false) {
    try {
        let route = `statuses/`;
        let parameters = { site: siteIds, per_page:1000 }
        if (distinct) {
            parameters.distinct = true
        }
        let response = await getAPIResponse(route, true, parameters);
        let results = response[0].data.results;
        let statuses = [];
        for (let result of results) {
            statuses.push(new Status(
                result['id'],
                result['created_at'],
                result['date'],
                result['isRaining'],
                result['isRepairing'],
                result['use'],
                result['summary'],
                result['site'],
            ));
        }
        statuses.sort((a, b) => (a.date < b.date) ? 1 : -1);
        return statuses;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function postNote(body, siteId, kind=NoteKind.Default) {

    try {
        let route = `notes/`;
        let parameters = {
            "site": siteId,
            "kind": kind,
            "body": body,
            "created_by": "2"
        }
        let response = await post(route, parameters);
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }

}

export async function getNotes(siteIds) {
    try {
        let route = `notes/`;
        let response = await getAPIResponse(route, true, { site: siteIds, per_page:1000 });
        let results = response[0].data.results;
        let notes = [];
        for (let result of results) {
            notes.push(new Note(
                result['id'],
                result['body'],
                result['created_at'],
                result['created_by'],
                result['site'],
                result['kind']
            ));
        }
        notes.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1);
        return notes;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export class Site {
    constructor(id, meterId, name, location, timezone, status, updatedAt, siteTest, installations, kind) {
        this.id = id;
        this.meterId = meterId;
        this.name = name;
        this.location = location;
        this.timezone = timezone;
        this.status = status;
        this.updatedAt = updatedAt;
        this.siteTest = siteTest;
        this.installations = installations;
        this.kind = kind
    }

    lastRun = () => {
        if (this.status?.opStatus == 'off') {
            return this.status?.opStatusStartTime;
        } else if (this.status?.opStatus == 'on') {
            return this.status?.opStatusLatestTime;
        }
        return 'N/A';
    };

    lastTimeMsgSent = () => {
        if (this.updatedAt) {
            let lastTimeMsgSent = new Date(this.updatedAt);
            return lastTimeMsgSent.toLocaleDateString();
        }
        return 'N/A';
    };
}

export function readableDate(date, options) {
    return date?.split('T')[0] ?? 'N/A';
}

class SiteLocation {
    constructor(latitude, longitude) {
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

class SiteStatus {
    constructor(opStatusLatestTime, opStatusStartTime, opStatus, gatewayLastSyncAt, lastPulseAt, lastCalibrationAt) {
        this.opStatusLatestTime = opStatusLatestTime;
        this.opStatusStartTime = opStatusStartTime;
        this.opStatus = opStatus;
        this.gatewayLastSyncAt = gatewayLastSyncAt;
        this.lastPulseAt = lastPulseAt;
        this.lastCalibrationAt = lastCalibrationAt;
    }
}

class Installation {
    constructor(id, installedTime, removedTime, hasControls, notes, createdAt, updatedAt, gateway, site, isOngoing) {
        this.id = id;
        this.installedTime = installedTime;
        this.removedTime = removedTime;
        this.hasControls = hasControls;
        this.notes = notes;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.gateway = gateway;
        this.site = site;
        this.isOngoing = isOngoing;
    }
}

class Photo {
    constructor(thumbnailUrl, url) {
        this.thumbnailUrl = thumbnailUrl;
        this.url = url;
    }
}

class Note {
    constructor(id, body, createdAt, createdBy, site, kind) {
        this.id = id;
        this.body = body;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.site = site;
        this.kind = kind;
    }
}

class Alarm {
    constructor(id, site, status, createdAt, updatedAt, acknowledgedAt, acknowledgedBy) {
        this.id = id;
        this.site = site;
        this.status = status;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.acknowledgedAt = acknowledgedAt;
        this.acknowledgedBy = acknowledgedBy;
    }
}

class Status {
    constructor(id, createdAt, date, isRaining, isRepairing, use, summary, site) {
        this.id = id;
        this.createdAt = createdAt;
        this.date = date;
        this.isRaining = isRaining;
        this.isRepairing = isRepairing;
        this.use = use;
        if (summary == "offline") {
            this.summary = "Offline";
        } else {
            this.summary = summary;
        }

        this.site = site;
    }
}

class Precipitation {
    constructor(id, createdAt, time, value, site, aggregation, source) {
        this.id = id;
        this.createdAt = createdAt;
        this.time = time;
        this.value = value;
        this.site = site;
        this.aggregation = aggregation;
        this.source = source;
    }
}