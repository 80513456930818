import { Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function TimeRangePicker(props) {
    return (
        <Row>
            <Col md={5}>
                <b>Start date</b>
                <DatePicker
                    selected={props.startTime}
                    onChange={props.handleDateChange}
                    onSelect={props.handleDateChange}
                    className="card shadow"
                    container="inline"
                    inputStyle={{ textAlign: 'center' }}
                />
            </Col>
            <Col md={5}>
                <b>End date</b>
                <DatePicker
                    selected={props.stopTime}
                    onChange={(date) => props.handleDateChange(date, false)}
                    onSelect={(date) => props.handleDateChange(date, false)}
                    closeOnScroll={true}
                    minDate={props.startTime}
                    className="shadow card"
                />
            </Col>
        </Row>
    )
}