import React from "react";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import getSites, { getGatewayInfo, readableDate, getStatuses, getAlarms, getNotes, postNote, acknowledgeAlarm, NoteKind } from "../../model/SiteModel";
import LeafletMapAfricaPump from "../map/LeafletMapAfricaPump";
import { Row, Card, Col, ListGroup, Button, Nav, ButtonGroup, Modal, ModalBody } from "react-bootstrap";
import AggregateStats from "../AggregateStats";
import { getRollupAggregates, getRollups, ROLLUP } from "../../model/RollupModel";
import PlotlyChart from "../plotlyChart";
import TimeRangePicker from "../TimeRangePicker";
import logo from "./logoTransparentBackgroundCopy.svg";
import Table from "../BasicTable.jsx"
import AlarmsTable from "../AlarmsTable.jsx"
import NoteCreation from "../NoteCreation.jsx"

// passes the route info to the Site route 
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
};

class AfricaPumpSiteDetail extends React.Component {

    siteId = this.props.router.params.id;

    constructor(props) {
        super(props);
        let [startTime, stopTime] = this.getTime();
        this.state = {
            site: null,
            waterRollupAgg: 0,
            hourRollupAgg: 0,
            waterRollups: [],
            hourRollups: [],
            startTime: startTime, // start in jan 2017
            stopTime: stopTime,
            loading: false,
            gateways: null,
            isExporting: false,
            activeTab: 0,
            statuses: [],
            alarms: [],
            notes: [],
            showNoteModal: false,
            dismissingAlarm: null
        };

        this.fetchRollupAgg = this.fetchRollupAgg.bind(this);
        this.fetchRollups = this.fetchRollups.bind(this);
        this.fetchGateway = this.fetchGateway.bind(this);
        this.getSite = this.getSite.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.gatewayInfo = this.gatewayInfo.bind(this);
    }

    componentDidMount() {
        this.getTime();
        this.getSite();
        this.fetchStatuses();
        this.fetchAlarms();
        this.fetchNotes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.startTime !== this.state.startTime || prevState.stopTime !== this.state.stopTime) {
            console.log('Updating page...');
            localStorage.setItem('startTime', this.state.startTime.toISOString());
            localStorage.setItem('stopTime', this.state.stopTime.toISOString());
            this.fetchRollups();
        }
    }

    handleNoteModalClose = (didSave = false) => {
        if (this.state.showNoteModal) {
            this.fetchNotes();
        }
        if (didSave) {
            this.clearAlarm();
        }
        this.setState({
            showNoteModal: false,
            dismissingAlarm: null
        });
    }

    addNoteClicked = (event) => {
        event.preventDefault();
        this.setState({ showNoteModal: true });
    }

    handleAlarmClicked = (alarm) => {
        console.log(alarm);
        this.setState({
            dismissingAlarm: alarm,
            showNoteModal: true
        })
    }

    clearAlarm() {
        if (!this.state.dismissingAlarm) {
            return
        }
        let alarm = this.state.dismissingAlarm;
        if (alarm.acknowledgedAt == null) {
            console.log('Acknowledging alarm: ' + alarm.id);
            acknowledgeAlarm(alarm.id).then(() => {
                this.fetchAlarms();
            });
        }
        this.setState({
            dismissingAlarm: null
        })

    }

    getTime() {
        let today = new Date();
        let seasonBefore = new Date(today.setFullYear(today.getFullYear() - 1));

        let startTime = seasonBefore.toISOString();
        let stopTime = new Date().toISOString();

        return [new Date(startTime), new Date(stopTime)];
    }

    getSite() {
        getSites(this.siteId).then((site) => {
            if (site.length > 0) {
                this.setState({
                    site: site[0]
                });
                this.fetchRollups(site[0]);

            } else {
                this.props.router.navigate('/dashboard');
            }
        });
    }

    earliestInstallationDate(installations) {
        let earliestInstalledTime;

        if (!installations) {
            installations = this.state.site?.installations;
        }
        if (installations.length > 0) {
            earliestInstalledTime = new Date(installations.slice(-1)[0].installedTime);
        }
        if (earliestInstalledTime && earliestInstalledTime > this.state.startTime) {
            return earliestInstalledTime;
        } else {
            return this.state.startTime;
        }
    }

    async fetchGateway() {
        if (this.state.site?.installations) {
            let gateways = await getGatewayInfo(this.state.site.installations.map((i) => { return i.gateway }));
            if (gateways) {
                this.setState({
                    gateways: gateways
                });
            }
        }
    }

    async fetchRollups(site) {
        if (!site) {
            site = this.state.site;
        }
        try {
            let res = await getRollups([this.siteId], this.earliestInstallationDate(site?.installations), this.state.stopTime);


            let waterVolumes = res[ROLLUP.Water] ?? [];
            let runtimes = res[ROLLUP.Runtime] ?? [];
            let energies = res[ROLLUP.Energy] ?? [];

            this.setState({
                waterRollups: waterVolumes,
                hourRollups: runtimes,
                energyRollups: energies,
            });

            this.fetchGateway();
            this.fetchRollupAgg();

        } catch (e) {
            console.log(e);
        }
    }

    async fetchRollupAgg() {
        try {
            let res = await getRollupAggregates([this.siteId], this.earliestInstallationDate(), this.state.stopTime);

            let waterIndex = res.findIndex((r) => r.type() === ROLLUP.Water);
            if (waterIndex > -1) {
                let waterVolume = res[waterIndex].value;
                this.setState({
                    waterRollupAgg: Math.round(waterVolume),
                });
            }

            let runTimeIndex = res.findIndex((r) => r.type() === ROLLUP.Runtime);
            if (runTimeIndex > -1) {
                let runtime = res[runTimeIndex].value;
                this.setState({
                    hourRollupAgg: Math.round(runtime),
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async fetchStatuses() {
        try {
            let statuses = await getStatuses([this.siteId]);
            var site = this.state.site;
            site.useStatus = statuses[0];
            this.setState({
                site: site,
                statuses: statuses,
            });
        } catch (e) {
            console.log("error fetching statuses", e);
        }
    }

    async fetchAlarms() {
        try {
            let alarms = await getAlarms([this.siteId]);
            this.setState({
                alarms: alarms,
            });
        } catch (e) {
            console.log("error fetching alarms", e);
        }
    }

    async fetchNotes() {
        try {
            let notes = await getNotes([this.siteId]);
            this.setState({
                notes: notes,
            });
        } catch (e) {
            console.log("error fetching notes", e);
        }
    }



    handleDateChange(d, isStartDate = true) {
        if (isStartDate) {
            if (d < this.state.stopTime) {
                this.setState({ startTime: d });
            }
        } else {
            if (d > this.state.startTime) {
                this.setState({ stopTime: d });
            }
        }
    }

    tabToggleClicked = (e) => {
        this.setState({ activeTab: e.target.id });
    }

    map() {
        const loc = [this.state.site.location.latitude, this.state.site.location.longitude];
        return (<Card className="shadow">
            <LeafletMapAfricaPump sites={[this.state.site]} selectedSites={[this.state.site.id]} centerLoc={loc} zoomLevel={16} useSatellite={true} hideLayers={true}></LeafletMapAfricaPump>
        </Card>);
    }

    gatewayInfo() {
        if (this.state.gateways) {

            const getCharge = () => {
                let charge = 0;
                // find non-removed installation
                let idx = this.state.site?.installations?.findIndex((install) => install.removedTime == null);
                if (idx > -1) {
                    let id = this.state.site?.installations[idx].gateway;
                    // get the right gateway
                    let i = this.state.gateways.findIndex((g) => g.id === id);
                    if (i > -1) {
                        // get state of charge
                        let gi = this.state.gateways[i]['latest_diagnostic_metrics'].findIndex((m) => m.sensor_kind === 'gateway_state_of_charge');
                        if (gi > -1) {
                            charge = this.state.gateways[i]['latest_diagnostic_metrics'][gi].value;
                        }
                    }
                }
                return `${charge} %`;
            };
            const titles = ['Battery Charge', 'Latest Sync'];
            const values = [getCharge(), [readableDate(this.state.site?.status?.opStatusLatestTime)]];

            return (
                <Row className="py-3">
                    <Col>
                        <AggregateStats titles={titles} values={values} loading={false} showTitles={true} />
                    </Col>
                </Row>
            );
        } else {
            return (<>Loading</>);
        }
    }

    installationInfo() {
        if (!this.state.gateways) {
            return
        }
        var installations = [...this.state.site?.installations];
        if (installations.length == 0) {
            return (<Card className="shadow">
                <Card.Body>
                    <Card.Title>No installation information</Card.Title>
                </Card.Body>
            </Card>);
        }
        var sections = [];
        console.log(installations);
        if ( !installations[0].removedTime ) {
            const currentInstallation = installations.shift();
            console.log(currentInstallation);
            let idx = this.state.gateways.findIndex((g) => g.id == currentInstallation.gateway);
            let barcode = this.state.gateways[idx]['barcode'];
            let model = this.state.gateways[idx]['gateway_model'];
            sections.push(
                    <Row className="py-2">
                        <Col>
                            <b>Current Installation</b>
                            <Card className="shadow">
                                <Card.Body>
                                    <ListGroup variant="flush"><Card.Header>Installed: {readableDate(currentInstallation?.installedTime)}</Card.Header>
                                        <ListGroup.Item>Barcode: {barcode}</ListGroup.Item>
                                        <ListGroup.Item>Model: {model}</ListGroup.Item>
                                        <ListGroup.Item>Removed At: {readableDate(currentInstallation?.removedTime)}</ListGroup.Item></ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )
        }
        if (!installations.length) {
            return sections;
        }
        sections.push(
            <Row className="py-2">
                <Col>
                    <b>Past Installations</b>
                    <Card className="shadow">
                        <Card.Body>
                            {installations.map((install) => {
                                let idx = this.state.gateways.findIndex((g) => g.id == install.gateway);
                                let barcode = this.state.gateways[idx]['barcode'];
                                let model = this.state.gateways[idx]['gateway_model'];
                                return (<ListGroup variant="flush"><Card.Header>Installed: {readableDate(install?.installedTime)}</Card.Header>
                                    <ListGroup.Item>Barcode: {barcode}</ListGroup.Item>
                                    <ListGroup.Item>Model: {model}</ListGroup.Item>
                                    <ListGroup.Item>Removed At: {readableDate(install?.removedTime)}</ListGroup.Item></ListGroup>);
                            })}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
        return sections;
    }

    waterGraph() {
        return (
            <Row>
                <Col className="py-2">
                    <b>Water</b>
                    <Card className="shadow">
                        <Card.Body>
                            <PlotlyChart items={this.state.waterRollups} yAxisTitle={"Water Volume (ac⋅ft)"} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }

    runtimeChart() {
        return (
            <Row>
                <Col className="py-2">
                    <b>Total Runtime</b>
                    <Card className="shadow">
                        <Card.Body>
                            <PlotlyChart height={160} items={this.state.hourRollups} statuses={this.uniqueStatuses()} yAxisTitle={"Pump Runtime (hrs)"} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }

    topBar() {
        return (<Row className="topbar" style={{ backgroundColor: "#005151" }}>
            <Col className="topbar">
                <Nav.Item>
                    <Nav.Link href="/dashboard">
                        <img
                            src={"https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/logo.svg?alt=media&token=073cf581-cfec-4198-9835-5f38831c5844"}
                            alt="Virridy"
                            style={{ margin: "8px", padding: "8px", marginLeft: "16px", width: "60px" }}
                        />
                    </Nav.Link>
                </Nav.Item>
            </Col>
            <Col>
                <ButtonGroup className="d-block mx-auto shadow" style={{ float: "right", margin: "20px" }}>
                    <Button id={0} active={this.state.activeTab == 0} onMouseDown={e => e.preventDefault()} onClick={this.tabToggleClicked}>Info</Button>
                    <Button id={1} active={this.state.activeTab == 1} onMouseDown={e => e.preventDefault()} onClick={this.tabToggleClicked}>Map</Button>
                </ButtonGroup>
            </Col>
        </Row>
        )
    }

    uniqueStatuses() {
        if (this.state.statuses.length == 0) {
            return [];
        }
        var currentStatus = this.state.statuses[0];
        var statuses = [currentStatus];
        for (let status of this.state.statuses) {
            if (status.summary != currentStatus.summary) {
                statuses.push(status);
                currentStatus = status;
            }
        }
        return statuses;
    }

    isInAlarm() {
        for (let alarm of this.state.alarms) {
            if (alarm.acknowledgedAt == null) {
                return true;
            }
        }
        return false;
    }

    statusSummaryDiv() {

        let statuses = this.uniqueStatuses();
        if (statuses.length == 0) {
            return (
                <Row className="py-3">
                    <b>No Usage Data</b>
                </Row>
            )

        }
        let titles = ['Current Use', 'Previous']
        var values = []
        if (statuses.length > 1) {
            values = [statuses[0].summary, statuses[1].summary]
        } else {
            values = [statuses[0].summary, "none"]
        }

        return (
            <Row className="py-3">
                <Col>
                    <AggregateStats titles={titles} values={values} loading={false} showTitles={true} />
                </Col>
            </Row>
        )
    }

    usageTotalsDiv() {
        let titles = ['Volume (1yr)', 'Hours (1yr)']
        let values = [this.state.waterRollupAgg, this.state.hourRollupAgg]
        return (
            <Row className="py-3">
                <Col>
                    <AggregateStats titles={titles} values={values} loading={false} showTitles={true} />
                </Col>
            </Row>
        )

    }

    statusTable() {
        let columns = [
            {
                Header: 'Status',
                accessor: 'summary',
            },
            {
                Header: 'Date',
                accessor: 'date',
            }
        ]

        let data = this.uniqueStatuses();

        return (
            <Row>
                <Col className="py-2">
                    <b>Status</b>
                    <Card className="shadow">
                        <Table
                            columns={columns}
                            data={data}
                            defaultPageSize={10}
                        />
                    </Card>
                </Col>
            </Row>
        )
    }

    notesTable() {
        let columns = [
            {
                Header: '',
                accessor: 'kindEmoji',
            },
            {
                Header: 'Date',
                accessor: 'readableCreatedAt',
            },
            {
                Header: 'Note',
                accessor: 'body',
            }
        ]

        let data = this.state.notes;
        for (let note of data) {
            note.readableCreatedAt = readableDate(note.createdAt);
            if (note.kind == NoteKind.Alarm) {
                note.kindEmoji = "⚠️"
            } else {
                note.kindEmoji = "📝"
            }
        }

        return (
            <Row>
                <Modal className="shadow" show={this.state.showNoteModal} onHide={() => this.handleNoteModalClose()} centered={true}>
                    <ModalBody>
                        <NoteCreation siteId={this.siteId} onSubmit={() => this.handleNoteModalClose(true)} alarm={this.state.dismissingAlarm} />
                    </ModalBody>
                </Modal>
                <Col className="py-2">
                    <b>Notes</b> <Button size="sm" onClick={this.addNoteClicked}>+</Button>

                    <Card className="shadow" style={{ marginTop: "8px" }}>
                        <Table
                            columns={columns}
                            data={data}
                            defaultPageSize={10}
                        />
                    </Card>
                </Col>
            </Row>
        )
    }

    alarmsTable() {

        let data = []
        for (let alarm of this.state.alarms) {
            if (alarm.acknowledgedAt == null) {
                alarm.readableCreatedAt = readableDate(alarm.createdAt);
                alarm.kindEmoji = "⚠️"
                data.push(alarm)
            }
        }
        if (!data.length) {
            return
        }

        let columns = [
            {
                Header: '',
                accessor: 'kindEmoji',
            },
            {
                Header: 'Date',
                accessor: 'readableCreatedAt',
            },
            {
                Header: '',
                accessor: 'dismiss',
            }
        ]

        return (
            <Row>
                <Col className="py-2">
                    <b>Alarms</b>
                    <Card className="shadow">
                        <AlarmsTable
                            columns={columns}
                            data={data}
                            defaultPageSize={10}
                            showPagination={true}
                            onDismissClicked={this.handleAlarmClicked}
                        />
                    </Card>
                </Col>
            </Row>
        )

    }

    render() {
        if (!this.state.site) {
            return (<></>);
        }
        return (
            <>
                <Col className="d-sm-none">
                    {this.topBar()}
                    <Row style={{ paddingLeft: "24px" }}>
                        {(this.state.activeTab == 0) ?
                            <Row className="pt-5" style={{ paddingRight: "0px", marginRight: "0px" }}>
                                <h3>{this.state.site.name}</h3>
                                {this.alarmsTable()}

                                {this.statusSummaryDiv()}
                                {this.statusTable()}
                                {this.gatewayInfo()}
                                {this.runtimeChart()}
                                {this.usageTotalsDiv()}
                                {this.notesTable()}
                                {this.installationInfo()}
                            </Row>
                            : this.map()}
                    </Row>
                </Col>
                <Col className="d-none d-sm-block">
                    <Row>
                        <Col>
                            <Row style={{ paddingRight: 0 }}>
                                <Col md={11}><h3>{this.state.site.name}</h3></Col>
                            </Row>
                            {this.statusSummaryDiv()}
                            {this.statusTable()}
                            {this.usageTotalsDiv()}
                            {this.notesTable()}
                            {this.gatewayInfo()}
                        </Col>

                        <Col>
                            <Row className="py-3">
                                {this.map()}
                            </Row>

                            <Row className="py-3">
                                <b>Total Runtime</b>
                                <Card className="shadow">
                                    <Card.Body>
                                        <PlotlyChart items={this.state.hourRollups} yAxisTitle={"Pump Runtime (hrs)"} />
                                    </Card.Body>
                                </Card>
                            </Row>
                        </Col>
                    </Row>
                </Col >
            </>
        );
    }
}

export default withRouter(AfricaPumpSiteDetail);
