import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Login from "./Login";
import { AuthProvider, PreventLoginPage, RequireAuth } from "../model/useAuth";
import Dashboard from './Dashboard';
import UsaPumpSiteList from "./usaPumps/UsaPumpSiteList";
import SiteDetail from "./usaPumps/UsaPumpSiteDetail";
import AfricaPumpSiteList from "./africaPumps/AfricaPumpSiteList";
import AfricaPumpSiteDetail from "./africaPumps/AfricaPumpSiteDetail";
import AfricaFilterSiteList from "./africaFilters/AfricaFilterSiteList";
import AfricaFilterSiteDetail from "./africaFilters/AfricaFilterSiteDetail";
import Sidebar from "./Sidebar";
import PageNotFound from "./PageNotFound";

// const farmerPages =  {'Home': '', 'DR': '/dr'};

export default function App() {
    return (
        <React.StrictMode>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route index path="login" element={
                            <PreventLoginPage>
                                <Login />
                            </PreventLoginPage>} />
                        <Route index path="/" element={
                            <PreventLoginPage>
                                <Login />
                            </PreventLoginPage>} />
                        <Route path="dashboard" element={
                            <RequireAuth>
                                <Dashboard />
                            </RequireAuth>
                        } />
                        <Route path="usa_pumps" element={
                            <Row className="justify-content-between">
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <Sidebar />
                                </Col>
                                <Col sm={10} md={10} lg={10} xl={10} className="floatRight">
                                    <RequireAuth>
                                        {<UsaPumpSiteList />}
                                    </RequireAuth>
                                </Col>
                            </Row>} />
                        <Route path='usa_pumps/site/:id' element={
                            <Row className="justify-content-between">
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <Sidebar />
                                </Col>
                                <Col sm={10} md={10} lg={10} xl={10} className="floatRight">
                                    <RequireAuth>
                                        {<SiteDetail />}
                                    </RequireAuth>
                                </Col>
                            </Row>}
                        />
                        <Route path="africa_pumps" element={
                            <Row className="justify-content-between">
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <Sidebar />
                                </Col>
                                <Col sm={10} md={10} lg={10} xl={10} className="floatRight">
                                    <RequireAuth>
                                        {<AfricaPumpSiteList />}
                                    </RequireAuth>
                                </Col>
                            </Row>}
                        />
                        <Route path='africa_pumps/site/:id' element={
                            <Row className="justify-content-between">
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <Sidebar />
                                </Col>
                                <Col sm={10} md={10} lg={10} xl={10} className="floatRight">
                                    <RequireAuth>
                                        {<AfricaPumpSiteDetail />}
                                    </RequireAuth>
                                </Col>
                            </Row>}
                        />
                        <Route path="africa_filters" element={
                            <Row className="justify-content-between">
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <Sidebar />
                                </Col>
                                <Col sm={10} md={10} lg={11} xl={11} className="floatRight">
                                    <RequireAuth>
                                        {<AfricaFilterSiteList />}
                                    </RequireAuth>
                                </Col>
                            </Row>}
                        />
                        <Route path='africa_filters/site/:id' element={
                            <Row className="justify-content-between">
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <Sidebar />
                                </Col>
                                <Col sm={10} md={10} lg={11} xl={11} className="floatRight">
                                    <RequireAuth>
                                        {<AfricaFilterSiteDetail />}
                                    </RequireAuth>
                                </Col>
                            </Row>}
                        />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </React.StrictMode>
    );
}