import { Row, Col, Card, Fade } from "react-bootstrap";

export default function AggregateStats(props) {
    // titles, values, loading = false, showTitles = true
    return (
        <Row>
            {props.titles.map((t) =>
            (<Col key={t}>
                {/* lg={3} className="align-items-stretch" */}
                <b>{props.showTitles ? t : ''}</b>
                <Card className="shadow" aria-controls="fade-text" aria-expanded={!props.loading}>
                    <Card.Body>
                    <div style={{ textAlign:"center" }}>
                        {props.values[props.titles.indexOf(t)]}
                        </div>
                    </Card.Body>
                </Card>
            </Col>)
            )}
        </Row>
    );
}