import React from 'react';
import L, { latLngBounds } from 'leaflet';
import { MapContainer, TileLayer, useMap, WMSTileLayer, LayersControl } from 'react-leaflet'
import { Marker, Popup } from 'react-leaflet';

class MapLayer {
    constructor(url, label, layers) {
        this.url = url ? `https://maps.isric.org/mapserv?map=/map/${url}.map` : '';
        this.label = label;
        this.layers = layers;
    }
}

class LeafletMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            layers: null,
        };
        this.markers = this.markers.bind(this);
        this.siteIsSelected = this.siteIsSelected.bind(this);
    }

    // class vars
    selectedMarker = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/selected_marker.png?alt=media&token=ccb7c6ba-eb86-4e57-a921-287b079e168c",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    unselectedMarker = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/unselected_marker.png?alt=media&token=1e84846b-90de-4e98-8bf7-e5e19d65fe02",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    zoomLevel = this.props?.zoomLevel ?? 5;
    centerLoc = this.props?.centerLoc ?? [38.1485, -121.1023];
    opacity = 0.5;

    componentDidUpdate(prevProps) {
        // when parent props change, re-render
        if (prevProps != this.props) {
            this.forceUpdate();
        }
    }

    siteIsSelected(site) {
        return this.props.selectedSites.includes(site.id);
    }

    markers() {
        // if sites are within selected sites, show markers
        // on click, setState

        return this.props.sites.map((site) => {
            if (site.location) {
                let latlng = new L.latLng(site.location.latitude, site.location.longitude);
                return (<Marker position={latlng} icon={this.siteIsSelected(site) ? this.selectedMarker : this.unselectedMarker} key={site.id} zIndexOffset={this.siteIsSelected(site) ? 1000 : -1000} data={site} eventHandlers={{
                    click: (e) => this.props.onMarkerClick ? this.props.onMarkerClick(e) : console.log(`tapped marker at: ${e.latlng}`),
                    mouseover: (e) => e.target.openPopup()
                }}>
                    {this.props.popup ? this.props.popup(site) : <></>}
                </Marker>);
            }
        });

    }

    render() {
        const layers = [
            new MapLayer(undefined, 'No Layer', undefined),
            new MapLayer('ocs', 'Organic Carbon Stock', 'ocs_0-30cm_mean'),
            new MapLayer('bdod', 'Bulk Density', 'bdod_0-5cm_mean'),
            new MapLayer('wrb', 'WRB Classification', 'MostProbable')
        ];
        return <div style={{ height: this.props.height ?? '500px', width: '100%', zIndex: 0 }}>
            <MapView
                markers={this.markers()}
                center={this.centerLoc}
                zoom={this.zoomLevel}
                layers={layers}
                opacity={this.opacity}
                useSatellite={this.props.useSatellite}
                hideLayers={this.props.hideLayers}
                height={this.props.height}
                >
            </MapView>
        </div>
    }

}

export default LeafletMap;

function ChangeView({ center, markers, zoom }) {
    const map = useMap();
    map.setView({ lng: center[1], lat: center[0] }, zoom);

    let markerBounds = latLngBounds([]);
    if (markers.length && markers.length > 0){
        markers.forEach(marker => {
          markerBounds.extend([marker.props.position.lat, marker.props.position.lng])
        })
        markerBounds.isValid() && map.fitBounds(markerBounds)
      }
    return null;
}

function MapView({ markers, center, zoom, layers, opacity, useSatellite, hideLayers, height }) {
    return (
        <MapContainer center={center} zoom={zoom} scrollWheelZoom={false} style={{ height: height ?? '500px', width: '100%', borderRadius: '0.5rem' }}>
            {markers}
            <ChangeView center={center} markers={markers} />
            <TileLayer
                url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}'
                id={useSatellite ? 'mapbox/satellite-v9' : 'mapbox/light-v10'}
                accessToken="pk.eyJ1IjoiemVrZXNoZWFyZXIiLCJhIjoiY2wxbXBuNXpjMG00ZDNlbW1kdmE5NzltbSJ9.bW4ZS6jHH8vsWhKPfV1ZJw"
            />
            {hideLayers ? <></> :
                <LayersControl position="topright">
                    {layers.map((layer) => {
                        return (<LayersControl.BaseLayer checked={layer.label == layers[0].label} name={layer.label} key={layer.url}>
                            <WMSTileLayer
                                url={layer.url}
                                layers={layer.layers}
                                opacity={opacity}
                            />
                        </LayersControl.BaseLayer>);
                    })}
                </LayersControl>}

        </MapContainer>
    )
}

