import React from "react";
import { useTable } from 'react-table'
import {Button} from "react-bootstrap";


export default function AlarmsTable({ columns, data, onDismissClicked }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

    } = useTable(
        {
            columns,
            data,
        },
    )

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} style={{ width: 1, borderBottom: "1px solid #005151", padding: "15px" }} >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} style={{borderBottom: "1px solid #f5f5f5" }}>
                                    {row.cells.map(cell => {
                                        if (cell.column.id == 'dismiss') {
                                            return (<Button variant="outline-primary" style={{border:"0px", padding:"15px"}} onMouseDown={e => e.preventDefault()} onClick={() => onDismissClicked(row.original)}>Dismiss</Button>)
                                        } else {
                                            return (<td {...cell.getCellProps()} style={{ padding:"10px"}}>{cell.render('Cell')}</td>)
                                        }

                                    })}
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </table>
        </>
    )
}