import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Outlet, Routes, Route, Link } from "react-router-dom";
import UsaPumpSiteList from "./usaPumps/UsaPumpSiteList";
import AfricaPumpSiteList from "./africaPumps/AfricaPumpSiteList";
import AfricaFilterSiteList from "./africaFilters/AfricaFilterSiteList";
import { Container, Row, Col, Card } from "react-bootstrap";
import Sidebar from "./Sidebar.jsx";
import { SiteKind, getUserSiteKinds } from "../model/SiteModel";

let NavTitle = class {
    constructor(link, title, element) {
        this.link = link;
        this.title = title;
        this.element = element;
    }
}

// Entry point for which dashboard to use
export default function Dashboard() {

    const navigate = useNavigate();
    const [userSiteKinds, setUserSiteKinds] = useState([]);

    async function fetchSiteKinds() {
        let siteKinds = await getUserSiteKinds();
        setUserSiteKinds(siteKinds);
    }

    useEffect(() => {
        console.log("Using effect, fetching site kinds");
        fetchSiteKinds();
    }, []);


    let navItems = []
    if ( userSiteKinds.includes(SiteKind.USAPump)) {
        navItems.push([new NavTitle('/usa_pumps', 'Home', (<UsaPumpSiteList/>))]);
    } 
    if ( userSiteKinds.includes(SiteKind.AfricaPump)) {
        navItems.push([new NavTitle('/africa_pumps', 'Home', (<AfricaPumpSiteList/>))]);
    }
    if ( userSiteKinds.includes(SiteKind.AfricaFilter)) {
        navItems.push([new NavTitle('/africa_filters', 'Home', (<AfricaFilterSiteList/>))]);
    }

    if (userSiteKinds.length == 1) {
        navigate(navItems[0][0].link);
    } else if (userSiteKinds.length > 1) {
        return (
            <Row className="justify-content-between">
                <Col sm={1} md={1} lg={1} xl={1}>
                    <Sidebar />
                </Col>
                <Col sm={10} className="farmer-table">
                    <h1>Choose a Dashboard</h1>
                    {userSiteKinds.includes(SiteKind.USAPump) ? 
                    <Card>
                        <Card.Body>
                            <Link to="/usa_pumps">USA Pump Manager</Link>
                        </Card.Body>
                    </Card> : null}
                    {userSiteKinds.includes(SiteKind.AfricaPump) ? 
                    <Card>
                        <Card.Body>
                            <Link to="/africa_pumps">Africa Pump Manager</Link>
                        </Card.Body>
                    </Card> : null}
                    {userSiteKinds.includes(SiteKind.AfricaFilter) ? 
                    <Card>
                        <Card.Body>
                            <Link to="/africa_filters">Africa Filter Manager</Link>
                        </Card.Body>
                    </Card> : null}
                </Col>
            </Row>
        )
    }

}
