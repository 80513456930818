import React from "react";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import getSites, { getGatewayInfo, getPhotos, readableDate } from "../../model/SiteModel";
import LeafletMap from "../map/LeafletMap";
import { Container, Row, Card, Col, Spinner, Fade, ListGroup, Button, ModalBody, Table } from "react-bootstrap";
import AggregateStats from "../AggregateStats";
import { getRollupAggregates, getRollups, fetchExportData, ROLLUP, ROLLUP_KINDS } from "../../model/RollupModel";
import PlotlyChart from "../plotlyChart";
import TimeRangePicker from "../TimeRangePicker";
import Image from "react-bootstrap/Image";
import { Modal } from "react-bootstrap";

// passes the route info to the Site route 
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
};

class SiteDetail extends React.Component {

    siteId = this.props.router.params.id;

    constructor(props) {
        super(props);
        let [startTime, stopTime] = this.getTime();
        this.state = {
            site: null,
            waterRollupAgg: 0,
            hourRollupAgg: 0,
            waterRollups: [],
            hourRollups: [],
            energyRollups: [],
            startTime: startTime, // start in jan 2017
            stopTime: stopTime,
            loading: false,
            gateways: null,
            photos: [],
            isExporting: false,
            showPhotoModal: false,
            photoModalUrl: null,
        };

        this.fetchRollupAgg = this.fetchRollupAgg.bind(this);
        this.fetchRollups = this.fetchRollups.bind(this);
        this.fetchGateway = this.fetchGateway.bind(this);
        this.fetchPhotos = this.fetchPhotos.bind(this);
        this.getSite = this.getSite.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.aggStats = this.aggStats.bind(this);
        this.gatewayInfo = this.gatewayInfo.bind(this);
        this.photoCards = this.photoCards.bind(this);
        this.handlePhotoModalShow = this.handlePhotoModalShow.bind(this);
        this.handlePhotoModalClose = this.handlePhotoModalClose.bind(this);
    }

    handlePhotoModalClose = () => {
        this.setState({ showPhotoModal: false, photoModalUrl: null });
    }

    handlePhotoModalShow = (url) => {
        if (url) {
            this.setState({ showPhotoModal: true, photoModalUrl: url });
        }
    }

    componentDidMount() {
        this.getTime();
        this.getSite();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.startTime !== this.state.startTime || prevState.stopTime !== this.state.stopTime) {
            console.log('Updating page...');
            localStorage.setItem('startTime', this.state.startTime.toISOString());
            localStorage.setItem('stopTime', this.state.stopTime.toISOString());
            this.fetchRollups();
        }
    }

    getTime() {
        let today = new Date();
        let seasonBefore = new Date(today.setMonth(today.getMonth() - 3));

        let startTime = localStorage.getItem('startTime') ?? seasonBefore.toISOString();
        let stopTime = localStorage.getItem('stopTime') ?? new Date().toISOString();

        return [new Date(startTime), new Date(stopTime)];
    }

    getSite() {
        getSites(this.siteId).then((site) => {
            if (site.length > 0) {
                this.setState({
                    site: site[0]
                });
                this.fetchRollups(site[0]);
            } else {
                // cannot find site id, then redirect
                this.props.router.navigate('/dashboard');
            }
        });
    }

    earliestInstallationDate(installations) {
        let earliestInstalledTime;

        if (!installations) {
            installations = this.state.site?.installations;
        }
        if (installations) {
            earliestInstalledTime = new Date(installations.slice(-1)[0].installedTime);
        }
        if (earliestInstalledTime && earliestInstalledTime > this.state.startTime) {
            return earliestInstalledTime;
        } else {
            return this.state.startTime;
        }

    }

    async fetchGateway() {
        if (this.state.site?.installations) {
            let gateways = await getGatewayInfo(this.state.site.installations.map((i) => { return i.gateway }));
            if (gateways) {
                this.setState({
                    gateways: gateways
                });
            }
        }
    }

    async fetchRollups(site) {
        if (!site) {
            site = this.state.site;
        }
        try {
            let res = await getRollups([this.siteId], this.earliestInstallationDate(site?.installations), this.state.stopTime);


            let waterVolumes = res[ROLLUP.Water];
            let runtimes = res[ROLLUP.Runtime];
            let energies = res[ROLLUP.Energy];

            this.setState({
                waterRollups: waterVolumes,
                hourRollups: runtimes,
                energyRollups: energies,
            });

            this.fetchGateway();
            this.fetchRollupAgg();
            this.fetchPhotos();

        } catch (e) {
            console.log(e);
        }
    }

    async fetchPhotos() {

        let photos = [];
        for (let installation of this.state.site?.installations) {
            let installationPhotos = await getPhotos(installation.id);
            photos.push(...installationPhotos);
        }
        this.setState({ photos: photos });
    }

    async fetchRollupAgg() {
        try {
            let res = await getRollupAggregates([this.siteId], this.earliestInstallationDate(), this.state.stopTime);

            let waterIndex = res.findIndex((r) => r.type() === ROLLUP.Water);
            let waterVolume = res[waterIndex].value;

            let runTimeIndex = res.findIndex((r) => r.type() === ROLLUP.Runtime);
            let runtime = res[runTimeIndex].value;

            this.setState({
                waterRollupAgg: Math.round(waterVolume),
                hourRollupAgg: Math.round(runtime),
            });
        } catch (e) {
            console.log(e);
        }
    }

    exportButtonClicked = (event) => {
        event.preventDefault();
        this.fetchExport()

    }

    async fetchExport() {

        this.setState({ isExporting: true });
        let results = await fetchExportData([this.siteId], this.state.startTime, this.state.stopTime);
        for (let item of results) {
            item.site = this.state.site.name;
        }
        console.log(results);
        const replacer = (key, value) => value === null ? '' : value
        const header = Object.keys(results[0])
        const csv = [
            header.join(','),
            ...results.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n')

        const filename = "export.csv";
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({ isExporting: false });
    }

    handleDateChange(d, isStartDate = true) {
        if (isStartDate) {
            if (d < this.state.stopTime) {
                this.setState({ startTime: d });
            }
        } else {
            if (d > this.state.startTime) {
                this.setState({ stopTime: d });
            }
        }
    }

    map() {
        const loc = [this.state.site.location.latitude, this.state.site.location.longitude];
        return (<Card className="shadow">
                <LeafletMap sites={[this.state.site]} selectedSites={[this.state.site.id]} centerLoc={loc} zoomLevel={16} useSatellite={true} hideLayers={true}></LeafletMap>
        </Card>);
    }

    aggStats() {
        const waterV = `${this.state.waterRollupAgg} ac⋅ft`;
        const hourR = `${this.state.hourRollupAgg} hrs`;
        const titles = ['Water', 'Hours Ran', 'Last Run'];
        const values = [waterV, hourR, readableDate(this.state.site.lastRun())];
        return <AggregateStats titles={titles} values={values} loading={false} showTitles={true} />;
    }

    gatewayInfo() {
        if (this.state.gateways) {

            const getCharge = () => {
                let charge = 0;
                // find non-removed installation
                let idx = this.state.site?.installations?.findIndex((install) => install.removedTime == null);
                if (idx > -1) {
                    let id = this.state.site?.installations[idx].gateway;
                    // get the right gateway
                    let i = this.state.gateways.findIndex((g) => g.id === id);
                    if (i > -1) {
                        // get state of charge
                        let gi = this.state.gateways[i]['latest_diagnostic_metrics'].findIndex((m) => m.sensor_kind === 'gateway_state_of_charge');
                        if (gi > -1) {
                            charge = this.state.gateways[i]['latest_diagnostic_metrics'][gi].value;
                        }
                    }
                }
                return `${charge} %`;
            };
            const titles = ['Battery', 'Latest Sync'];
            const values = [getCharge(), [readableDate(this.state.site?.status?.opStatusLatestTime)]];

            return (
                <Col>
                    <AggregateStats titles={titles} values={values} loading={false} showTitles={true} />
                    <Col className="py-2">
                        <b>Installation</b>
                        <Card className="shadow">
                            <Card.Body>
                                {this.state.site?.installations.map((install) => {
                                    let idx = this.state.gateways.findIndex((g) => g.id == install.gateway);
                                    let barcode = this.state.gateways[idx]['barcode'];
                                    let model = this.state.gateways[idx]['gateway_model'];
                                    return (<ListGroup variant="flush"><Card.Header>Installed: {readableDate(install?.installedTime)}</Card.Header>
                                        <ListGroup.Item>Barcode: {barcode}</ListGroup.Item>
                                        <ListGroup.Item>Model: {model}</ListGroup.Item>
                                        <ListGroup.Item>Removed At: {readableDate(install?.removedTime)}</ListGroup.Item></ListGroup>);
                                })}


                            </Card.Body>
                        </Card>
                    </Col>
                </Col>
            );
        } else {
            return (<>Loading</>);
        }
    }

    waterGraph() {
        return (<Col className="py-2">
            <b>Water</b>
            <Card className="shadow">
                <Card.Body>
                    <PlotlyChart items={this.state.waterRollups} yAxisTitle={"Water Volume (ac⋅ft)"} />
                </Card.Body>
            </Card>
        </Col>);
    }

    energyGraph() {
        return (<Col className="py-2">
            <b>Energy</b>
            <Card className="shadow">
                <Card.Body>
                    <PlotlyChart items={this.state.energyRollups} yAxisTitle={"Energy Consumed (kWh)"} />
                </Card.Body>
            </Card>
        </Col>);
    }

    photoCards() {
        // show no photos
        return (this.state.photos ? <Col className="py-2">
            <b>Photos</b>
            <Modal className="shadow" show={this.state.showPhotoModal} onHide={() => this.handlePhotoModalClose()} centered={true}>
                <ModalBody>
                    {this.state.showPhotoModal ? (
                        <Image src={this.state.photoModalUrl} fluid={true} style={{ objectFit: "cover", maxHeight: "50rem" }} />
                    ) : <></>}
                </ModalBody>
            </Modal>

            <Card className="shadow">
                <Card.Body>
                    <Row>
                        {this.state.photos?.map((p) => {
                            return (
                                <Col className="photo-column">
                                    <Button className="unstyled-button" onClick={() => this.handlePhotoModalShow(p.url)}>
                                        <Card.Img thumbnail={true} src={p.thumbnailUrl} style={{ height: "7.5rem", width: "7.5rem", objectFit: "cover", padding: 0 }}></Card.Img>
                                    </Button>
                                </Col>
                            );
                        })}
                    </Row>
                </Card.Body>
            </Card>
        </Col> : <>No photos for this site.<br /></>
        );
    }

    render() {
        return (
            <>
                {
                    this.state.site ? (
                        <Row className="pt-5">
                            <Row  style={{paddingRight:0 }}>
                                <Col  md={11}><h3>{this.state.site.name}</h3></Col>
                                <Col   md={1}  style={{ float: "right", paddingRight:0 }}>
                                    {(this.state.isExporting) ?
                                        <Spinner  style={{ float: "right"}} animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        :
                                        <Button style={{ float: "right"}} onClick={this.exportButtonClicked} onMouseDown={e => e.preventDefault()}>Export</Button>
                                    }
                                </Col>
                            </Row>

                            <Col md={5}>
                                <TimeRangePicker startTime={this.state.startTime} stopTime={this.state.stopTime} handleDateChange={this.handleDateChange} />
                                {this.aggStats()}
                                <Col className="py-3">
                                    {this.map()}
                                </Col>
                                {this.gatewayInfo()}
                            </Col>
                            <Col md={7}>
                                {this.waterGraph()}
                                {this.energyGraph()}
                                {this.photoCards()}
                            </Col>
                            {/* {this.table()} */}
                        </Row>) : <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }

            </>
        );
    }
}

export default withRouter(SiteDetail);