import React from "react";
import { Card, Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import useAuth from "../model/useAuth";
import { useLocation } from "react-router-dom";
import logo from "../assets/logoVertical.png";

export default function Login() {
    const userTypes = ['none', 'admin', 'farmer', 'water manager'];
    const [choice, setChoice] = React.useState("");
    const [username, setUser] = React.useState("");
    const [password, setPassword] = React.useState("");
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();

    const handleLogin = (e) => {
        e.preventDefault();
        // console.log(userTypes[choice]);

        login(username, password).then(() => {
            navigate(state?.path || '/dashboard');
        });
    }

    return (
        <Container className="max-width-720 py-5">
            <Card body className="shadow">
                <Card.Body>
                    <div className="p-1">
                        <img
                            src={logo}
                            height="100"
                            className="d-block mx-auto"
                            alt="Virridy Logo"
                        />
                    </div>
                    <Form onSubmit={handleLogin}>
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="username" placeholder="Enter username" onChange={(c) => setUser(c.target.value)} value={username.value} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={(c) => setPassword(c.target.value)} value={password.value} />
                        </Form.Group>
                        <Button className="d-block mx-auto" variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
}