import React from "react";
import { Card, Container} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { postNote, NoteKind } from "../model/SiteModel";


export default function NoteCreation(props) {

    const [noteBody, setNoteBody] = React.useState("");

    const handleNoteCreation = (e) => {
        e.preventDefault();
        console.log(props.siteId);
        console.log("Creating note with body: " + noteBody);
        let kind = props.alarm ? NoteKind.Alarm : NoteKind.Default
        if (noteBody.length > 0) {
            postNote(noteBody, props.siteId, kind).then(() => {
                props.onSubmit();
            });
        }
    }

    return (
        <Container className="max-width-720 py-5">
            <Card>
                <Card.Body>
                    <Form onSubmit={handleNoteCreation}>
                        <Form.Group className="mb-3">
                            <Form.Label>{props.alarm ? "Enter note to dismiss" : "New Note"}</Form.Label>
                            <Form.Control as="textarea" rows={4} placeholder="Enter text" onChange={(e) => setNoteBody(e.target.value)} value={noteBody} />
                        </Form.Group>
                        <Button className="d-block mx-auto" variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );

}