import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useAuth from "../model/useAuth";
import logoSmall from "../assets/logoTransparentBackground.svg";
import { SiteKind } from "../model/SiteModel";
import { getUserSiteKinds } from "../model/SiteModel";

export default function Sidebar(props) {
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    }

    const [userSiteKinds, setUserSiteKinds] = useState([]);

    async function fetchSiteKinds() {
        let siteKinds = await getUserSiteKinds();
        setUserSiteKinds(siteKinds);
    }

    useEffect(() => {
        console.log("Using effect, fetching site kinds");
        fetchSiteKinds();
    }, []);

    return (
        <Nav
            className="d-none d-sm-block sidebar flex-column align-items-center"
            style={{ width: "90px", position: "fixed" }}
        >
            <Nav.Item>
                <Nav.Link  onClick={() => {
                        navigate('/dashboard');
                    }}>
                    <img
                        src={logoSmall}
                        alt="Virridy Logo"
                        style={{ padding: "8px", position: "fixed", width: "60px" }}
                    />
                    <br />
                    <br />
                    <br />
                </Nav.Link>
            </Nav.Item>
            {userSiteKinds.includes(SiteKind.USAPump) && userSiteKinds.length > 1 ?
                <Nav.Item className=" justify-content-end">
                    <Nav.Link onClick={() => {
                        navigate('/usa_pumps');
                    }} style={{textAlign:"center"}}>USA Pumps</Nav.Link>
                </Nav.Item>
                : null}
            {userSiteKinds.includes(SiteKind.AfricaPump) && userSiteKinds.length > 1  ?
                <Nav.Item className=" justify-content-end">
                    <Nav.Link onClick={() => {
                        navigate('/africa_pumps');
                    }} style={{textAlign:"center"}}>Africa Pumps</Nav.Link>
                </Nav.Item>
                : null}
            {userSiteKinds.includes(SiteKind.AfricaFilter) && userSiteKinds.length > 1 ?
                <Nav.Item className=" justify-content-end">
                    <Nav.Link onClick={() => {
                        navigate('/africa_filters');
                    }} style={{textAlign:"center"}}>Africa Filters</Nav.Link>
                </Nav.Item>
                : null}
            <Nav.Item className=" justify-content-end end-logout">
                <Nav.Link onClick={() => {
                    console.log("Logging out");
                    handleLogout();
                }}>Logout</Nav.Link>
            </Nav.Item>
        </Nav>
    );
};