import React from "react";
import { Row, Col, Button, ButtonGroup, Nav, Card } from "react-bootstrap";
import logoSmall from "../../assets/logoTransparentBackground.svg";
import getSites, { readableDate } from "../../model/SiteModel";
import { getRollupAggregates, ROLLUP_KINDS } from "../../model/RollupModel";
import { useTable, useSortBy } from 'react-table'
import { Link } from "react-router-dom";
import LeafletMapAfricaPump from "../map/LeafletMapAfricaPump";
import { Popup } from "react-leaflet";
import PlotlyChart from "../plotlyChart";
import { getStatuses, getAlarms, SiteKind } from "../../model/SiteModel";


export default class AfricaPumpSiteList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: { 'Home': '' },
            activeTab: 0,
            runtimeRollups: [],
            sites: [],
            statuses: [],
            alarms: [],
        };
        this.popup = this.popup.bind(this);
        this.fetchSites = this.fetchSites.bind(this);
    }

    componentDidMount() {
        console.log("componentDidMount");
        this.fetchSites();
    }

    popup(site) {
        return (<Popup>
            <b>{site?.name}</b> <br /> {`Status: ${site.useStatus?.summary ?? "Unknown"}`}<br />
            <div style={{
                justifyContent: 'right',
                display: 'flex'
            }}>
                <Link to={`site/${site.id}`}>
                    <Button size='sm' variant="outline-primary">
                        More
                    </Button>
                </Link>
            </div>
        </Popup>);
    }

    siteNameFromId(id) {
        for (let site of this.state.sites) {
            if (site.id == id) {
                return site.name
            }
        }
        return "Unknown"
    }

    async fetchSites() {
        let parameters = {
            "kind": SiteKind.AfricaPump,
            "use_simplified": "True"
        }
        getSites(null, parameters).then((sites) => {
            this.setState({
                loading: false,
                sites: sites,
            });
            this.fetchAggregates();
            this.fetchStatuses();
            this.fetchAlarms();
        });
    }

    async fetchAggregates() {

        const [startTime, stopTime] = this.getTime();
        const siteIds = this.state.sites.map(site => site.id);

        try {
            let timeAggs = await getRollupAggregates(siteIds, startTime, stopTime, "time", ROLLUP_KINDS.weeklyTotalWater);

            this.setState({
                runtimeRollups: timeAggs,
            });

        } catch (e) {
            console.log("error fetching aggregates", e);
        }
    }

    async fetchStatuses() {
        const siteIds = this.state.sites.map(site => site.id);
        try {
            let statuses = await getStatuses(siteIds, true);
            let sites = this.state.sites;
            for (let status of statuses) {
                for (let site of sites) {
                    if (site.id == status.site) {
                        site.useStatus = status;
                    }
                }
            }
            this.setState({
                sites: sites,
            });
        } catch (e) {
            console.log("error fetching statuses", e);
        }
    }

    async fetchAlarms() {
        const siteIds = this.state.sites.map(site => site.id);
        try {
            let alarms = await getAlarms(siteIds);
            this.setState({
                alarms: alarms,
            });
        } catch (e) {
            console.log("error fetching alarms", e);
        }
    }

    getTime() {
        let today = new Date();
        let seasonBefore = new Date(today.setFullYear(today.getFullYear() - 1));

        let startTime = seasonBefore.toISOString();
        let stopTime = new Date().toISOString();

        return [new Date(startTime), new Date(stopTime)];
    }

    tabToggleClicked = (e) => {
        this.setState({ activeTab: e.target.id });
    }

    topBar() {
        return (<Row className="topbar" style={{ backgroundColor: "#005151" }}>
            <Col className="topbar">
                <Nav.Item>
                    <Nav.Link href="/dashboard">
                        <img
                            src={logoSmall}
                            alt="Virridy Logo"
                            style={{ margin: "8px", padding: "8px", marginLeft: "16px", width: "60px" }}
                        />
                    </Nav.Link>
                </Nav.Item>
            </Col>
            <Col>
                <ButtonGroup className="d-block mx-auto shadow" style={{ float: "right", margin: "20px" }}>
                    <Button id={0} active={this.state.activeTab == 0} onMouseDown={e => e.preventDefault()} onClick={this.tabToggleClicked}>List</Button>
                    <Button id={1} active={this.state.activeTab == 1} onMouseDown={e => e.preventDefault()} onClick={this.tabToggleClicked}>Map</Button>
                </ButtonGroup>
            </Col>
        </Row>
        )
    }

    map() {
        var sites = this.state.sites;
        sites.sort(function(a, b){
            if (!a.useStatus && !b.useStatus) {
                return 0
            } else if (a.useStatus && b.useStatus) {
                return a.useStatus.summary.localeCompare(b.useStatus.summary);
            } else {
                return a.useStatus ? -1 : 1;
            }
        });
        return (
            <LeafletMapAfricaPump sites={sites} popup={this.popup} selectedSites={[]} onMarkerClick={this.onMarkerClick}></LeafletMapAfricaPump>
        )
    }

    table() {

        const currentStatus = (site) => {
            if (site.useStatus != null) {
                var emoji = "";
                if (site.useStatus.summary == "Normal Use") {
                    emoji = "🟢 ";
                } else if (site.useStatus.summary == "Low Use") {
                    emoji = "🟡 ";
                } else if (site.useStatus.summary == "No Use") {
                    emoji = "🔴 ";
                } else if (site.useStatus.summary == "Offline") {
                    emoji = "⚪ ";
                } else if (site.useStatus.summary == "Seasonal Disuse") {
                    emoji = "🔵 ";
                }
                return emoji + site.useStatus.summary;
            }
            return "--";
        }
        const currentAlarm = (site) => {
            for (let alarm of this.state.alarms) {
                if (alarm.site == site.id) {
                    if (alarm.acknowledgedAt == null) {
                        return "⚠️";
                    }
                }
            }
        }

        let tableSites = this.state.sites.sort((a, b) => a.name.localeCompare(b.name));
        for (let site of tableSites) {
            site.currentStatus = currentStatus(site);
            site.currentAlarm = currentAlarm(site);
        }

        let columns = [
            {
                Header: 'Site',
                accessor: 'name',
            },
            {
                Header: 'Status',
                accessor: 'currentStatus',
            },
            {
                Header: 'Alarm',
                accessor: 'currentAlarm',
            }
        ]

        let data = tableSites;

        return (
            <Table
                columns={columns}
                data={data}
                showPagination={false}
                defaultPageSize={data.length}
            />
        );
    }

    render() {
        return (
            <>
                <Col className="d-sm-none">
                    {this.topBar()}
                    <Row>
                        {(this.state.activeTab == 0) ? <div className="farmer-table">
                            {this.table()}
                        </div> : this.map()}
                    </Row>
                </Col>
                <Col className="d-none d-sm-block">
                    <Row>
                        <Col>
                            <Card className="farmer-table shadow">
                                {this.table()}
                            </Card>
                        </Col>
                        <Col>
                            <Row className="py-3">
                                {this.map()}
                            </Row>
                            <Row className="py-3">
                                <b>Total Runtime</b>
                                <Card className="shadow">
                                    <Card.Body>
                                        <PlotlyChart items={this.state.runtimeRollups} yAxisTitle={"Pump Runtime (hrs)"} />
                                    </Card.Body>
                                </Card>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </>
        );
    }
}

function Table({ columns, data }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

    } = useTable(
        {
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: 'currentAlarm',
                        desc: true
                    }
                ]
            }
        },
        useSortBy,
    )

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: 1, borderBottom: "1px solid #005151", padding: "15px" }} >
                                    {column.render('Header')}
                                    <span>
                                        {column.id != "selection" ? column.isSorted ? column.isSortedDesc ? ' ↓' : ' ↑' : ' ↕' : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} style={{ padding: "30px", borderBottom: "1px solid #f5f5f5" }}>
                                    {row.cells.map(cell => {
                                        if (cell.column.id == 'name') {
                                            return (<Link to={`site/${row.original.id}`}><td {...cell.getCellProps()} style={{ paddingRight: "15px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "15px" }}>{cell.render('Cell')}</td></Link>)
                                        } else if (cell.column.id == 'currentStatus') {
                                            return (<td {...cell.getCellProps()} style={{ paddingRight: "15px", paddingLeft: "15px", width:"200px" }}><Link to={`site/${row.original.id}`}>{cell.render('Cell')}</Link></td>)
                                        } else {
                                            return (<td {...cell.getCellProps()} style={{ paddingRight: "15px", paddingLeft: "15px" }}><Link to={`site/${row.original.id}`}>{cell.render('Cell')}</Link></td>)
                                        }

                                    })}
                                </tr>
                            );
                        }
                    )}
                </tbody>
            </table>
        </>
    )
}