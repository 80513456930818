import Plot from 'react-plotly.js';

class PlotlyChart extends Plot {

    isUnmounting = false

    constructor(props) {
        super(props);

        this.state = { 
            line: this.chartLine(), 
            layout: {
                yaxis: { title: { text: this.props.yAxisTitle }, rangemode: "tozero" },
                autosize: true,
                responsive: true,
                height: this.props.height ?? 270,
                bargap: 0.5,
                margin: {
                    l: 50,
                    r: 0,
                    b: 30,
                    t: 0,
                    pad: 0
                },
            }, 
            config: {
                displayModeBar: false,
            },
        }
    }

    componentDidMount() {
        this.isUnmounting = false
    }

    componentWillUnmount() {
        this.isUnmounting = true
    }

    chartLine () {
        var times = []
        var values = []
        if (this.props.items.length > 0) {
            for (let item of this.props.items) {
                times.push(item.time)
                values.push(item.value)
            }
        }

        return {
            x: times,
            y: values,
            type: 'bar',
            marker:{color:'#005151', line:{color:'#005151', width:2}}
        }
    }

    render() {
        if ( this.isUnmounting ) {
            return <></>
        } else {
            return <Plot 
            data={[this.chartLine()]}
             layout={this.state.layout} 
             config={this.state.config} 
             useResizeHandler={true}
             style={{width: "100%", height: "100%"}}
              />
        }

    }
}

export default PlotlyChart;