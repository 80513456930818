import getAPIResponse from "./PumphausModel";

const rollupKindsRoute = 'rollup_kinds';
export const ROLLUP_KINDS = {
    fifteenlyTotalWater: 5,
    hourlyTotalWater: 49,
    dailyTotalWater: 50,
    weeklyTotalWater: 51,
    monthlyTotalWater: 52,
    runtimeFifteen: 43,
    runtimeHourly: 45,
    runtimeDaily: 46,
    runtimeWeekly: 47,
    runtimeMonthly: 48,
    fifteenlyEnergy: 4,
    hourlyEnergy: 53,
    dailyEnergy: 54,
    weeklyEnergy: 55,
    monthlyEnergy: 56,
    cumulativeWaterHourly: 57,
    cumulativeWaterDaily: 58,
    cumulativeWaterWeekly: 59,
    cumulativeWaterMonthly: 60,
};
export const ROLLUP = {
    Water: 'water', Runtime: 'runtime', Energy: 'energy', CumulativeWater: 'cumulativeWater'
}
const waterMap = [ROLLUP_KINDS.fifteenlyTotalWater, ROLLUP_KINDS.hourlyTotalWater, ROLLUP_KINDS.dailyTotalWater, ROLLUP_KINDS.weeklyTotalWater, ROLLUP_KINDS.monthlyTotalWater];
const runtimeMap = [ROLLUP_KINDS.runtimeFifteen, ROLLUP_KINDS.runtimeHourly, ROLLUP_KINDS.runtimeDaily, ROLLUP_KINDS.runtimeWeekly, ROLLUP_KINDS.runtimeMonthly];
const energyMap = [ROLLUP_KINDS.fifteenlyEnergy, ROLLUP_KINDS.hourlyEnergy, ROLLUP_KINDS.dailyEnergy, ROLLUP_KINDS.weeklyEnergy, ROLLUP_KINDS.monthlyEnergy];
const cumulativeWaterMap = [ROLLUP_KINDS.cumulativeWaterHourly, ROLLUP_KINDS.cumulativeWaterDaily, ROLLUP_KINDS.cumulativeWaterWeekly, ROLLUP_KINDS.cumulativeWaterMonthly];
const rollupAggregateRoute = 'rollups/aggregate/';
const rollupRoute = 'rollups/';
const acreFtConversion = 1233.48;
const kwHConversion = 3.6 * Math.pow(10, 6);

export default async function getRollupKinds() {
    try {
        let results = await getAPIResponse(rollupKindsRoute);
        let rollup_kinds = [];
        for (const res of results) {
            let rollup_kind = res.data.results.map((ro) => {
                return new RollupKind(ro['id'], ro['label'], ro['period'], ro['unit']);
            });
            rollup_kinds.push(rollup_kind);
        }
        return rollup_kinds;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function getRollupAggregates(sites, startTime, stopTime, groupBy, rollupKind, isFilter=false, isCumulative=false) {
    
    let rks = rollupKind ? [rollupKind] : getRollupKindsFromDuration(startTime, stopTime, false, isFilter, isCumulative);
    let params = { rollup_kind: rks, start_time: startTime.toISOString(), stop_time: stopTime.toISOString(), group_by: "rollup_kind" };
    if (sites) {
        params["site"] = sites;
    }
    if (groupBy) {
        params["group_by"] = [groupBy, "rollup_kind"];
    } else {
        params["group_by"] = "rollup_kind";
    }

    try {
        let results = await getAPIResponse(rollupAggregateRoute, false, params);
        let rollups = [];
        for (const res of results) {
            res.data.map((ro) => {
                let value = ro['value'];
                let rk = ro['rollup_kind'];
                if (waterMap.indexOf(rk) > -1 && !isFilter) {
                    value = ro['value'] / acreFtConversion;
                }
                let r = new RollupAggregate(ro['site'], ro['time'], value, rk);
                rollups.push(r);
            });
        }
        return rollups;
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function fetchExportData(sites, startTime, stopTime, isFilter=false) {

    let rollupKinds = getRollupKindsFromDuration(startTime, stopTime, false, isFilter, false);
    let params = { rollup_kind: rollupKinds, start_time: startTime.toISOString(), stop_time: stopTime.toISOString(), group_by: ["time", "site", "rollup_kind"], ordering: "time"};
    if (sites) {
        params["site"] = sites;
    }
    try {
        let response = await getAPIResponse(rollupAggregateRoute, true, params);
        let results = response[0].data;
        for (let item of results) {
            item.rollup_kind = rollupNameFromId(item.rollup_kind);

          }
        return results;

    } catch (e) {
        console.error(e);
        return null;
    }
  }

export async function getRollups(sites, startTime, stopTime, isFilter=false, isCumulative=false) { // TODO: there is no group by option for rolup 
    let rollupK = getRollupKindsFromDuration(startTime, stopTime, true, isFilter, isCumulative);
    let params = { site: sites, rollup_kind: rollupK, start_time: startTime.toISOString(), stop_time: stopTime.toISOString(), per_page: 1000, ordering: "time" };
    try {
        let results = await getAPIResponse(rollupRoute, true, params);
        let rollups = { 'water': [], 'energy': [], 'runtime': [], 'cumulativeWater': [] };
        for (const res of results) {
            res.data.results.map((ro) => {
                let value = ro['value'];
                let rk = ro['rollup_kind'];
                if (waterMap.indexOf(rk) > -1 && !isFilter) {
                    value = ro['value'] / acreFtConversion;
                    let r = new Rollup(ro['site'], ro['time'], value, rk);
                    rollups[ROLLUP.Water].push(r);
                } else if (energyMap.indexOf(rk) > -1) {
                    value = ro['value'] / kwHConversion;
                    let r = new Rollup(ro['site'], ro['time'], value, rk);
                    rollups[ROLLUP.Energy].push(r);
                } else if (cumulativeWaterMap.indexOf(rk) > -1) {
                    value = ro['value'];
                    let r = new Rollup(ro['site'], ro['time'], value, rk);
                    rollups[ROLLUP.CumulativeWater].push(r);
                } else {
                    let r = new Rollup(ro['site'], ro['time'], value, rk);
                    rollups[ROLLUP.Runtime].push(r);
                }
            }, this);

        }
        return rollups;
    } catch (e) {
        console.error(e);
        return null;
    }
}

function diffHrs(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
}

function getRollupKindsFromDuration(startTime, stopTime, includeEnergy = true, isFilter = false, isCumulative = false) {
    let durationInHrs = diffHrs(stopTime, startTime);

    const maxPoints = 100; // want to have around 100 data points in graph
    let timeIdx; // time = ['15', 'hr', 'day', 'week', 'month']

    if (isFilter && isCumulative) {
        if (durationInHrs < maxPoints) {
            timeIdx = 0;
        } else if (durationInHrs < maxPoints * 24) {
            timeIdx = 1;
        } else if (durationInHrs < maxPoints * 24 * 7) {
            timeIdx = 2;
        } else {
            timeIdx = 3;
        }
        return [cumulativeWaterMap[timeIdx]]
    }


    if (durationInHrs < maxPoints / 4) {
        timeIdx = 0;
    } else if (durationInHrs < maxPoints) {
        timeIdx = 1;
    } else if (durationInHrs < maxPoints * 24) {
        timeIdx = 2;
    } else if (durationInHrs < maxPoints * 24 * 7) {
        timeIdx = 3;
    } else {
        timeIdx = 4;
    }

    if (includeEnergy) {
        return [waterMap[timeIdx], runtimeMap[timeIdx], energyMap[timeIdx]] ?? [ROLLUP_KINDS.runtimeHourly];
    } else {
        return [waterMap[timeIdx], runtimeMap[timeIdx]] ?? [ROLLUP_KINDS.runtimeHourly];
    }

}

function rollupNameFromId(id) {
    switch (id) {
    case 5:
        return "15 Minutely Water Volume"
    case 49: 
        return "Hourly Water Volume"
      case 50:
        return "Daily Water Volume"
      case 51:
        return "Weekly Water Volume"
      case 52:
        return "Monthly Water Volume"
      case 46:
        return "Daily Runtime"
      case 47:
        return "Weekly Runtime"
      case 48:
        return "Monthly Runtime"
      default:
        return "Unknown"
    }
  }

class RollupAggregate {
    constructor(site, time, value, rollupKind) {
        this.site = site;
        this.time = time;
        this.value = value;
        this.rollupKind = rollupKind;
    }

    type = () => {
        if (waterMap.indexOf(this.rollupKind) > -1) {
            return ROLLUP.Water;
        } else if (energyMap.indexOf(this.rollupKind) > -1) {
            return ROLLUP.Energy;
        } else if (runtimeMap.indexOf(this.rollupKind) > -1) {
            return ROLLUP.Runtime;
        }
    };
}

class RollupKind {
    constructor(id, label, period, unit) {
        this.id = id;
        this.label = label;
        this.period = period;
        this.unit = unit;
    }
}

class Rollup {
    constructor(site, time, value, rollupKind) {
        this.site = site;
        this.time = time;
        this.value = value;
        this.rollupKind = rollupKind;
    }
}