import * as React from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getUserSiteKinds } from "./SiteModel";
import { SiteKinds } from "./SiteModel";

const userContext = React.createContext();
const PUMPHAUS_URL = "https://pumphaus.us-east-1.elasticbeanstalk.com";

// const UserType =  {
//     None : -1,
//     Admin: 0,
//     Farmer: 1,
//     WaterManager: 2,
// }

function useAuth() {
    const [user, setUser] =
        React.useState(() =>
            localStorage.getItem("authToken") ? localStorage.getItem("authToken") : null);
    const [userSiteKinds, setUserSiteKinds] = React.useState([]);

    function setToken(token) {
        localStorage.setItem("authToken", token);
        setUser(token);
    }

    return {
        user,
        login(username, password) {
            // sign in via server then get response and set user
            return new Promise((resolve, reject) => {
                getUser(username, password).then((r) => {
                    if (r) {
                        let token = r.data.token;
                        setToken(token);
                        resolve();
                    } else {
                        reject("Could not login");
                    }
                });
            });
        },
        logout() {
            return new Promise((res) => {
                setToken("");
                res();
            });
        },
    };
}

async function getUser(username, password) {
    try {
        if (username && password) {
            const data = { "username": username, "password": password };
            // console.log(`${username}\n${password}`);
            const response = await axios.post(`${PUMPHAUS_URL}/api-token-auth/`, data);
            // console.log(response.data.token);
            return response;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
        alert("Incorrect username or password");
        return null;
    }
}


export function AuthProvider({ children }) {
    const auth = useAuth();
    return <userContext.Provider value={auth}>{children}</userContext.Provider>;
}

export default function AuthConsumer() {
    return React.useContext(userContext);
}

export function RequireAuth({ children }) {
    const { user } = useAuth();
    const location = useLocation();

    return user
        ? children
        : <Navigate to='/login' replace state={{ path: location.pathname }} />
}

// don't allow login page when logged in
export function PreventLoginPage({ children }) {
    const { user } = useAuth();
    const location = useLocation();
    console.log(user);
    return user && location.pathname == '/login' ?
        <Navigate to='/dashboard' replace state={{ path: location.pathname }} /> : children;
}