import React from "react";
import { useTable, usePagination } from 'react-table'
import {Button} from "react-bootstrap"

export default function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    )

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} style={{ width: 1, borderBottom: "1px solid #005151", padding: "15px" }} >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} style={{ padding: "30px", borderBottom: "1px solid #f5f5f5" }}>
                                    {row.cells.map(cell => {
                                        if (cell.column.id == 'kindEmoji') {
                                            return (<td {...cell.getCellProps()} style={{paddingRight: "0px", paddingLeft: "15px" }}>{cell.render('Cell')}</td>)
                                        } else if (cell.column.id == 'readableCreatedAt') {
                                            return (<td {...cell.getCellProps()} style={{width:"100px"}}>{cell.render('Cell')}</td>)
                                        } else {
                                            return (<td {...cell.getCellProps()} style={{padding: "10px"}}>{cell.render('Cell')}</td>)
                                        }

                                    })}
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </table>
            {data.length > 10 ? <div className="pagination">
                <Button variant="outline-primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={{border:"0px"}}>
                    {'<<'}
                </Button>{' '}
                <Button variant="outline-primary" onClick={() => previousPage()} disabled={!canPreviousPage} style={{border:"0px"}}>
                    {'<'}
                </Button>{' '}
                <Button variant="outline-primary" onClick={() => nextPage()} disabled={!canNextPage} style={{border:"0px"}}>
                    {'>'}
                </Button>{' '}
                <Button variant="outline-primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={{border:"0px"}}>
                    {'>>'}
                </Button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
            </div>
            : null}
            
        </>
    )
}