import React from 'react';
import L, { latLngBounds } from 'leaflet';
import { MapContainer, TileLayer, useMap, WMSTileLayer, LayersControl } from 'react-leaflet'
import { Marker, Popup } from 'react-leaflet';

class MapLayer {
    constructor(url, label, layers) {
        this.url = url ? `https://maps.isric.org/mapserv?map=/map/${url}.map` : '';
        this.label = label;
        this.layers = layers;
    }
}

class LeafletMapAfricaPump extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            layers: null,
        };
        this.markers = this.markers.bind(this);
        this.siteIsSelected = this.siteIsSelected.bind(this);
    }

    // class vars
    selectedMarkerGreen = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/selected_marker.png?alt=media&token=ccb7c6ba-eb86-4e57-a921-287b079e168c",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    unselectedMarkerGreen = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/unselected_marker.png?alt=media&token=1e84846b-90de-4e98-8bf7-e5e19d65fe02",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    selectedMarkerYellow = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/selected_marker_yellow.png?alt=media&token=2ee26321-8748-4b0b-9f7c-74568b77b18d",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    unselectedMarkerYellow = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/unselected_marker_yellow.png?alt=media&token=d5198dca-ff1a-4e69-b3be-01bc05064f2a",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    selectedMarkerRed = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/selected_marker_red.png?alt=media&token=21154d8d-9a75-4750-bbd1-39595f2686e0",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    unselectedMarkerRed = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/unselected_marker_red.png?alt=media&token=5a5523f0-14d9-4899-baaf-b9ed160568a6",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    selectedMarkerGray = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/selected_marker_grey.png?alt=media&token=fe87cfc6-8e0d-4c00-95d8-86e677778ec1",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    unselectedMarkerGray = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/unselected_marker_grey.png?alt=media&token=14214ce9-fc2b-4958-9c42-84f10ac245ab",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    selectedMarkerBlue = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/selected_marker_blue.png?alt=media&token=e52db6b7-4866-40a4-a620-0ee0df0611b1",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    unselectedMarkerBlue = new L.Icon({
        iconUrl: "https://firebasestorage.googleapis.com/v0/b/static-files-a24c7.appspot.com/o/unselected_marker_blue.png?alt=media&token=8b294430-c186-46d5-be3a-4a8ec7cc0408",
        iconSize: [24, 30],
        iconAnchor: [11, 28],
        popupAnchor: [-7, -45]
    });
    zoomLevel = this.props?.zoomLevel ?? 5;
    centerLoc = this.props?.centerLoc ?? [38.1485, -121.1023];
    opacity = 0.5;

    componentDidUpdate(prevProps) {
        // when parent props change, re-render
        if (prevProps != this.props) {
            this.forceUpdate();
        }
    }

    siteIsSelected(site) {
        return this.props.selectedSites.includes(site.id);
    }

    zIndexForSite(site) {
        let useStatus = site.useStatus;
        if (!useStatus) {
            return 0
        }
        if (useStatus.summary == "Normal Use") {
            return 10
        } else if (useStatus.summary == "Seasonal Disuse") {
            return 50
        } else if (useStatus.summary == "Low Use") {
            return 100
        } else if (useStatus.summary == "No Use") {
            return 1000
        } else {
            return 0
        }
    }

    markerIconForSite(site) {
        let useStatus = site.useStatus;
        if (!useStatus) {
            return this.siteIsSelected(site) ? this.selectedMarkerGray : this.unselectedMarkerGray;
        }
        if (useStatus.summary == "Normal Use") {
            return this.siteIsSelected(site) ? this.selectedMarkerGreen : this.unselectedMarkerGreen;
        } else if (useStatus.summary == "Low Use") {
            return this.siteIsSelected(site) ? this.selectedMarkerYellow : this.unselectedMarkerYellow;
        } else if (useStatus.summary == "No Use") {
            return this.siteIsSelected(site) ? this.selectedMarkerRed : this.unselectedMarkerRed;
        } else if (useStatus.summary == "Seasonal Disuse") {
            return this.siteIsSelected(site) ? this.selectedMarkerBlue : this.unselectedMarkerBlue;
        } else {
            return this.siteIsSelected(site) ? this.selectedMarkerGray : this.unselectedMarkerGray;
        }

    }

    markers() {
        return this.props.sites.map((site) => {
            if (site.location) {
                let latlng = new L.latLng(site.location.latitude, site.location.longitude);
                return (<Marker position={latlng} icon={this.markerIconForSite(site)} key={site.id} zIndexOffset={this.zIndexForSite(site)} data={site} eventHandlers={{
                    click: (e) => this.props.onMarkerClick ? this.props.onMarkerClick(e) : console.log(`tapped marker at: ${e.latlng}`),
                    mouseover: (e) => e.target.openPopup()
                }}>
                    {this.props.popup ? this.props.popup(site) : <></>}
                </Marker>);
            }
        });

    }

    render() {
        const layers = [
            new MapLayer(undefined, 'No Layer', undefined),
            new MapLayer('ocs', 'Organic Carbon Stock', 'ocs_0-30cm_mean'),
            new MapLayer('bdod', 'Bulk Density', 'bdod_0-5cm_mean'),
            new MapLayer('wrb', 'WRB Classification', 'MostProbable')
        ];
        return <div style={{ height: '500px', width: '100%', zIndex: 0 }}>
            <MapView
                markers={this.markers()}
                center={this.centerLoc}
                zoom={this.zoomLevel}
                layers={layers}
                opacity={this.opacity}
                useSatellite={this.props.useSatellite}
                hideLayers={this.props.hideLayers}>
            </MapView>
        </div>
    }

}

export default LeafletMapAfricaPump;

function ChangeView({ center, markers, zoom }) {
    const map = useMap();
    map.setView({ lng: center[1], lat: center[0] }, zoom);

    let markerBounds = latLngBounds([]);
    if (markers.length && markers.length > 0){
        markers.forEach(marker => {
          markerBounds.extend([marker.props.position.lat, marker.props.position.lng])
        })
        markerBounds.isValid() && map.fitBounds(markerBounds)
      }
    return null;
}

function MapView({ markers, center, zoom, layers, opacity, useSatellite, hideLayers }) {
    return (
        <MapContainer center={center} zoom={zoom} scrollWheelZoom={false} style={{ height: '500px', width: '100%', borderRadius: '0.5rem' }}>
            {markers}
            <ChangeView center={center} markers={markers} />
            <TileLayer
                url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}'
                id={useSatellite ? 'mapbox/satellite-v9' : 'mapbox/light-v10'}
                accessToken="pk.eyJ1IjoiemVrZXNoZWFyZXIiLCJhIjoiY2wxbXBuNXpjMG00ZDNlbW1kdmE5NzltbSJ9.bW4ZS6jHH8vsWhKPfV1ZJw"
            />
            {hideLayers ? <></> :
                <LayersControl position="topright">
                    {layers.map((layer) => {
                        return (<LayersControl.BaseLayer checked={layer.label == layers[0].label} name={layer.label} key={layer.url}>
                            <WMSTileLayer
                                url={layer.url}
                                layers={layer.layers}
                                opacity={opacity}
                            />
                        </LayersControl.BaseLayer>);
                    })}
                </LayersControl>}

        </MapContainer>
    )
}

