import React from "react";
import { Spinner, Container, Row, Col, Card, Placeholder, Form } from "react-bootstrap";
import getSites, { Site, SiteStatus, readableDate, SiteKind } from "../../model/SiteModel";
import LeafletMap from "../map/LeafletMap";
import { Popup } from "react-leaflet";
import { Fade, Button } from "react-bootstrap";
import { getRollupAggregates, fetchExportData, ROLLUP, ROLLUP_KINDS } from "../../model/RollupModel";
import PlotlyChart from "../plotlyChart";
import { Link } from "react-router-dom";
import TimeRangePicker from "../TimeRangePicker";
import AggregateStats from "../AggregateStats";
import { useTable, useSortBy, useRowSelect } from 'react-table'
import Sidebar from "../Sidebar";

export default class UsaPumpSiteList extends React.Component {
    constructor(props) {
        super(props);
        const [startTime, stopTime] = this.getTime();
        this.state = {
            pages: { 'Home': '', 'Demand Response': '/dr' },
            siteWater: [],
            selectedSites: [], // ids
            loading: true,
            aggregatesLoading: true,
            rollupKinds: [],
            waterRollups: [],
            hourRollups: [],
            startTime: startTime,
            stopTime: stopTime,
            totalWaterVolume: 0,
            totalRuntime: 0,
            isExporting: false,
            sites: []
        };
        this.fetchTotals = this.fetchTotals.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.allSelected = this.allSelected.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.popup = this.popup.bind(this);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.fetchAggregates = this.fetchAggregates.bind(this);
        this.fetchSitewiseAggregates = this.fetchSitewiseAggregates.bind(this);
        this.computeNum = this.computeNum.bind(this);
        this.getSitesSelected = this.getSitesSelected.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.fetchSites = this.fetchSites.bind(this);
    }

    componentDidMount() {
        this.fetchSites();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedSites !== this.state.selectedSites || prevState.startTime !== this.state.startTime || prevState.stopTime !== this.state.stopTime) {
            console.log('Updating page...');
            localStorage.setItem('startTime', this.state.startTime.toISOString());
            localStorage.setItem('stopTime', this.state.stopTime.toISOString());
            this.fetchTotals();
            this.fetchAggregates();
        }
        if (prevState.startTime !== this.state.startTime || prevState.stopTime !== this.state.stopTime || this.state.siteWater.length == 0) {
            this.fetchSitewiseAggregates();
        }
    }


    getTime() {
        let today = new Date();
        let seasonBefore = new Date(today.setMonth(today.getMonth() - 3));

        let startTime = localStorage.getItem('startTime') ?? seasonBefore.toISOString();
        let stopTime = localStorage.getItem('stopTime') ?? new Date().toISOString();

        return [new Date(startTime), new Date(stopTime)];
    }

    async fetchSitewiseAggregates() {
        if (this.state.selectedSites.length === 0) {
            this.setState({
                siteWater: [],
            });
            return;
        }
        let allSites = this.state.sites.map((s) => s.id);
        try {
            let siteAggs = await getRollupAggregates(allSites, this.state.startTime, this.state.stopTime, "site", ROLLUP_KINDS.dailyTotalWater);

            this.setState({
                siteWater: siteAggs,
                loading: false
            });
        } catch (e) {
            console.log("error fetching sitewise aggregates", e);
        }
    }

    fetchSites() {
        console.log("fetching sites");
        if (this.state.sites.length > 0) {
            return;
        }
        let parameters = {
            "kind": SiteKind.USAPump
        }
        getSites(null, parameters).then((sites) => {
            this.setState({
                loading: false,
                sites: sites,
                selectedSites: sites.map((s) => s.id),
            });
        });
    }

    async fetchAggregates() {

        if (this.state.selectedSites.length === 0) {
            this.setState({
                waterRollups: [],
                hourRollups: [],
            });
            return;
        }
        try {
            let timeAggs = await getRollupAggregates(this.state.selectedSites, this.state.startTime, this.state.stopTime, "time");

            let waterRollups = []
            let runtimeRollups = []
            for (let row of timeAggs) {
                if (row.type() === ROLLUP.Water) {
                    waterRollups.push(row);
                } else {
                    runtimeRollups.push(row);
                }
            }
            this.setState({
                waterRollups: waterRollups,
                hourRollups: runtimeRollups,
            });

        } catch (e) {
            console.log("error fetching aggregates", e);
        }
    }

    siteNameFromId(id) {
        for (let site of this.state.sites) {
            if (site.id == id) {
                return site.name
            }
        }
        return "Unknown"
    }

    exportButtonClicked = (event) => {
        event.preventDefault();
        this.fetchExport()
    }

    async fetchExport() {

        this.setState({ isExporting: true });
        let results = await fetchExportData(this.state.selectedSites.length ? this.state.selectedSites : this.state.sites, this.state.startTime, this.state.stopTime);
        for (let item of results) {
            item.site = this.siteNameFromId(item.site);
        }
        console.log(results);
        const replacer = (key, value) => value === null ? '' : value
        const header = Object.keys(results[0])
        const csv = [
            header.join(','),
            ...results.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n')

        const filename = "export.csv";
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({ isExporting: false });
    }

    async fetchTotals() {
        if (this.state.selectedSites.length != 0) {

            try {
                this.setState({
                    aggregatesLoading: true
                });
                let res = await getRollupAggregates(this.state.selectedSites, this.state.startTime, this.state.stopTime);

                let waterIndex = res.findIndex((r) => r.type() === ROLLUP.Water);
                let totalVolume = res[waterIndex].value;

                let runtimeIndex = res.findIndex((r) => r.type() === ROLLUP.Runtime);
                let totalRuntime = res[runtimeIndex].value;

                this.setState({
                    aggregatesLoading: false,
                    totalWaterVolume: Math.round(totalVolume),
                    totalRuntime: Math.round(totalRuntime),
                });

            } catch (e) {
                console.log(e);
            }
        } else {
            this.setState({
                aggregatesLoading: false,
                totalWaterVolume: 0,
                totalRuntime: 0,
            });
        }
    }

    onSelect(site) {
        if (site) {
            const index = this.state.selectedSites.indexOf(site.id);
            let selectedCopy = [...this.state.selectedSites];
            if (index > -1) { // if has item
                selectedCopy.splice(index, 1);
            } else {
                selectedCopy.push(site.id);
            }

            this.setState({ selectedSites: selectedCopy });
        }
    }

    allSelected() { return this.state.sites.every((s) => this.state.selectedSites.includes(s.id)); }
    siteSelected(site) { return this.state.selectedSites.includes(site.id); }
    getSitesSelected() {
        let selected = [];
        for (const site of this.state.sites) {
            if (this.state.selectedSites.includes(site.id)) {
                selected.push(site);
            }
        }
        return selected;
    }

    selectAll() {
        let siteCopy = [...this.state.sites];
        let select = !this.allSelected();
        let selected;

        if (select) {
            selected = siteCopy.map((s) => s.id);
        } else {
            selected = [];
        }

        this.setState({ selectedSites: selected });
    }

    computeNum(isOn = true) {
        var on = 0;
        var off = 0;
        let sites = this.getSitesSelected();
        for (const site of sites) {
            site.status?.opStatus == 'on' ? on++ : off++;
        }
        if (isOn) {
            return `${on}`;
        } else {
            return `${off}`;
        }
    }

    aggregateStats() {
        const titles = ['Volume', 'Runtime', 'Pumps on', 'Pumps off'];
        const values = [`${this.state.totalWaterVolume} ac⋅ft`, `${this.state.totalRuntime} hrs`, this.computeNum(true), this.computeNum(false)];
        // const icons = [waterPump, waterDrop];
        return <AggregateStats titles={titles} values={values} loading={this.state.aggregatesLoading} showTitles={true} />
    }

    popup(site) {
        // change to on hover instead of popup 
        return (<Popup>
            <b>{site?.name}</b> <br /> {`last run: ${readableDate(site?.lastRun())}`}<br />
            <div style={{
                justifyContent: 'right',
                display: 'flex'
            }}>
                <Link to={`site/${site.id}`}>
                    <Button size='sm' variant="outline-primary">
                        More
                    </Button>
                </Link>
            </div>
        </Popup>);
    }

    onMarkerClick(e) {
        let site = e.target.options.data;
        if (site) {
            this.onSelect(site);
        }
    }

    map() {
        return (
            <Card className="farmer-table shadow">
                <LeafletMap sites={this.state.sites} selectedSites={this.state.selectedSites} popup={this.popup} onMarkerClick={this.onMarkerClick}></LeafletMap>
            </Card>
        );
    }

    graphs() {
        return (<Col style={{ padding: "0.75rem" }}>
            <Row className="py-3">
                <b>Water</b>
                <Card className="shadow">
                    <Card.Body>
                        <PlotlyChart items={this.state.waterRollups} yAxisTitle={"Water Volume (ac⋅ft)"} />
                    </Card.Body>
                </Card>
            </Row>
            <Row className="py-3">
                <b>Runtime</b>
                <Card className="shadow">
                    <Card.Body>
                        <PlotlyChart items={this.state.hourRollups} yAxisTitle={"Pump Runtime (hrs)"} />
                    </Card.Body>
                </Card>
            </Row>
        </Col>
        );
    }

    handleDateChange(d, isStartDate = true) {
        if (isStartDate) {
            if (d < this.state.stopTime) {
                this.setState({ startTime: d });
            }
        } else {
            if (d > this.state.startTime) {
                this.setState({ stopTime: d });
            }
        }
    }

    siteList() {

        const waterVolume = (site) => {
            let index = this.state.siteWater.findIndex((w) => w.site == site.id);
            if (index != -1) {
                return Math.round(this.state.siteWater[index].value);
            } else {
                return 'N/A';
            }
        };

        const currentStatus = (site) => {
            let updatedDate = new Date(site.status?.opStatusLatestTime ?? 0);
            let now = new Date();
            let diff = Math.abs(now - updatedDate);
            let isDateStale = diff > 1000 * 60 * 60 * 24 * 7; // a week
            if (site.status?.opStatus == 'on' && !isDateStale) {
                return "On";
            } else if (site.status?.opStatus == 'off' && !isDateStale) {
                return "Off";
            } else {
                return "--";
            }
        }

        const lastRunDateString = (site) => {
            if (!site.lastRun() || site.lastRun() == 'N/A') {
                return "N/A";
            }
            let timezoneOffset = new Date().getTimezoneOffset();
            let lastRunDate = new Date(site.lastRun());
            lastRunDate.setMinutes(lastRunDate.getMinutes() - timezoneOffset);
            return lastRunDate.toISOString().split('T')[0];

        }

        var selectedRowIndexes = {};
        let tableSites = this.state.sites.sort((a, b) => a.name.localeCompare(b.name));
        var index = 0;
        for (let site of tableSites) {
            site.waterVolume = waterVolume(site);
            site.lastRunDate = lastRunDateString(site);
            site.currentStatus = currentStatus(site);
            selectedRowIndexes[`${index}`] = this.siteSelected(site)
            index += 1;
        }

        let columns = [
            {
                Header: 'Site',
                accessor: 'name',
            },
            {
                Header: 'Status',
                accessor: 'currentStatus',
            },
            {
                Header: 'Acre-feet',
                accessor: 'waterVolume',
            },
            {
                Header: 'Last run',
                accessor: 'lastRunDate',
            },
        ]

        let data = tableSites;
        return (
            <Table
                columns={columns}
                data={data}
                selectedSites={this.state.selectedSites}
                allSites={this.state.sites}
                showPagination={false}
                defaultPageSize={data.length}
                onCheckChange={this.onSelect}
                selectAll={this.selectAll}
            />)

    }

    render() {
        return (<>
            <Row className="m-3">
                <Col className="py-2">
                    <h3>My Fleet</h3>
                </Col>
                <Col className="col-2">
                    {(this.state.isExporting) ?
                        <div style={{ float: "right", width: "70px" }}><Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner></div>
                        :
                        <Button className={"d-block mx-auto"} style={{ float: "right" }} onClick={this.exportButtonClicked} onMouseDown={e => e.preventDefault()}>Export</Button>
                    }
                </Col>
            </Row>

            <Row className="m-3">
                <Col md={6}>
                    <TimeRangePicker startTime={this.state.startTime} stopTime=
                        {this.state.stopTime} handleDateChange={this.handleDateChange} />
                    <div className="farmer-table shadow">
                        {this.siteList()}
                    </div>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col>
                            {this.aggregateStats()}
                        </Col>
                    </Row>
                    {this.map()}
                    {this.graphs()}
                </Col>
            </Row>
        </>
        );
    }
}

const IndeterminateCheckbox = React.forwardRef(
    ({ onCheckChange, isSelected, site }) => {

        return (
            <>
                <Form.Check checked={isSelected} onChange={() => onCheckChange(site)} />
            </>
        )
    }
)


function Table({ columns, data, onCheckChange, selectAll, selectedSites, allSites }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

    } = useTable(
        {
            columns,
            data,
        },
        useSortBy,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} onCheckChange={selectAll} isSelected={selectedSites.length == allSites.length} />
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onCheckChange={onCheckChange} isSelected={selectedSites.includes(row.original.id)} site={row.original} />
                        </div>
                    ),
                },
                ...columns,
            ])
        },


    )

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: 1, borderBottom: "1px solid #005151", padding: "15px" }} >
                                    {column.render('Header')}
                                    <span>
                                        {column.id != "selection" ? column.isSorted ? column.isSortedDesc ? ' ↓' : ' ↑' : ' ↕' : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(
                        (row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} style={{ padding: "30px", borderBottom: "1px solid #f5f5f5" }}>
                                    {row.cells.map(cell => {
                                        if (cell.column.id == 'name') {
                                            return (<Link to={`site/${row.original.id}`}><td {...cell.getCellProps()} style={{ paddingRight: "15px", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "15px" }}>{cell.render('Cell')}</td></Link>)
                                        } else if (cell.column.id == 'waterVolume') {
                                            return (<td {...cell.getCellProps()} style={{ textAlign: "right", paddingRight: "15px", paddingLeft: "15px" }}>{cell.render('Cell')}</td>)
                                        } else {
                                            return (<td {...cell.getCellProps()} style={{ paddingRight: "15px", paddingLeft: "15px" }}>{cell.render('Cell')}</td>)
                                        }

                                    })}
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </table>
        </>
    )
}
